import React, { useEffect, useState } from "react";
import { useStaticQuery, graphql } from "gatsby";

import { Container, Typography, IconButton, SvgIcon } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import clsx from "clsx";

import { RUNLY_DARKER_BLUE, RUNLY_DARK_GRAY } from "../colors";
import tc from "tinycolor2";

import {
	Twitter as TwitterIcon,
	YouTube as YouTubeIcon,
	GitHub as GitHubIcon
} from "@material-ui/icons";

import { mdiDocker } from "@mdi/js";

import { Full as Logo } from "../logo";

import Link from "./link";

const platforms = ["win", "mac", "linux"];

const Footer = ({ maxWidth, className, ...props }) => {
	const [platform, setPlatform] = useState("");
	useEffect(() => {
		const platformString = navigator?.platform || navigator?.oscpu;
		const _platform = platforms.find(p =>
			new RegExp(p, "gi").test(platformString)
		);

		if (_platform) {
			setPlatform(_platform === "win" ? "windows" : _platform);
		}
	}, []);

	const classes = useStyles();

	const {
		site: {
			siteMetadata: { twitter, youtube, github, docker }
		},
		allFile: { nodes: legalDocs }
	} = useStaticQuery(graphql`
		query FooterQuery {
			site {
				siteMetadata {
					twitter
					youtube
					github
					docker
				}
			}
			allFile(filter: { sourceInstanceName: { eq: "legal" } }) {
				nodes {
					childMdx {
						frontmatter {
							title
						}
						fields {
							slug
						}
					}
				}
			}
		}
	`);

	return (
		<footer className={clsx(classes.root, className)} {...props}>
			<Container maxWidth={maxWidth}>
				<section>
					<ul className={classes.items}>
						<li>
							<Link href="/">
								<Logo alt="Runly Logo" className={classes.logo} />
							</Link>
						</li>

						<LinkList header="Product">
							<LinkListItem href="/">Background Processing</LinkListItem>
							<LinkListItem href="/platform/">Automation Platform</LinkListItem>
							<LinkListItem href="/ui/">UI</LinkListItem>
							<LinkListItem href="/pricing/">Pricing</LinkListItem>
						</LinkList>

						<LinkList header="Resources">
							<LinkListItem href="/docs/">Documentation</LinkListItem>
							<LinkListItem href="/videos/">Tutorial Videos</LinkListItem>
							<LinkListItem
								href={platform ? `/downloads/${platform}/` : `/downloads/`}
							>
								Downloads
							</LinkListItem>
							<LinkListItem href="/help/">Help & Support</LinkListItem>
						</LinkList>

						<LinkList header="Company">
							<LinkListItem href="/about/">About Us</LinkListItem>
							<LinkListItem href="/blog/">Blog</LinkListItem>
							{github ? (
								<LinkListItem href={`https://github.com/${github}`}>
									Open Source
								</LinkListItem>
							) : null}
							{legalDocs.map(
								({
									childMdx: {
										frontmatter: { title },
										fields: { slug }
									}
								}) => (
									<LinkListItem key={slug} href={slug}>
										{title}
									</LinkListItem>
								)
							)}
						</LinkList>
					</ul>
				</section>
				<section>
					<ul className={`${classes.items} ${classes.bottom}`}>
						<li>
							<Typography variant="subtitle2">© 2020 Runly LLC</Typography>
						</li>
						<li>
							{github ? (
								<IconButton
									color="inherit"
									aria-label="GitHub"
									title="GitHub"
									href={`https://github.com/${github}`}
									target="_blank"
									size="medium"
								>
									<GitHubIcon fontSize="large" />
								</IconButton>
							) : null}

							{docker ? (
								<IconButton
									color="inherit"
									aria-label="Docker"
									title="Docker"
									href={`https://hub.docker.com/u/${docker}`}
									target="_blank"
									size="medium"
								>
									<SvgIcon fontSize="large">
										<path d={mdiDocker} />
									</SvgIcon>
								</IconButton>
							) : null}

							{twitter ? (
								<IconButton
									color="inherit"
									aria-label="Twitter"
									title="Twitter"
									href={`https://twitter.com/${twitter}`}
									target="_blank"
									size="medium"
								>
									<TwitterIcon fontSize="large" />
								</IconButton>
							) : null}

							{youtube ? (
								<IconButton
									color="inherit"
									aria-label="YouTube"
									title="YouTube"
									href={`https://www.youtube.com/channel/${youtube}`}
									target="_blank"
									size="medium"
								>
									<YouTubeIcon fontSize="large" />
								</IconButton>
							) : null}
						</li>
					</ul>
				</section>
			</Container>
		</footer>
	);
};

const LinkList = ({ header, children }) => {
	const classes = useLinkListStyles();

	return (
		<li className={classes.root}>
			<Typography variant="h5" component="h4">
				{header}
			</Typography>
			<ul className={classes.list}>{children}</ul>
		</li>
	);
};

const LinkListItem = props => (
	<li>
		<Link color="inherit" {...props} />
	</li>
);

const useLinkListStyles = makeStyles(theme => ({
	root: {
		[theme.breakpoints.up("sm")]: {
			margin: 0
		},
		[theme.breakpoints.down("sm")]: {
			margin: theme.spacing(2, 0)
		}
	},
	list: {
		margin: theme.spacing(1, 0),
		padding: 0,
		listStyleType: "none"
	}
}));

const useStyles = makeStyles(theme => {
	const darkMode = theme.palette.type === "dark";
	return {
		root: {
			backgroundColor: darkMode
				? tc.mix(RUNLY_DARK_GRAY, RUNLY_DARKER_BLUE).darken()
				: RUNLY_DARKER_BLUE,
			color: theme.palette.getContrastText(RUNLY_DARKER_BLUE)
		},
		logo: {
			height: theme.spacing(3),
			[theme.breakpoints.up("md")]: {
				height: theme.spacing(6)
			}
		},
		items: {
			margin: 0,
			padding: theme.spacing(5, 0),
			listStyleType: "none",
			display: "flex",
			flexWrap: "wrap",
			justifyContent: "space-between",

			[theme.breakpoints.up("sm")]: {
				flexDirection: "row"
			},
			[theme.breakpoints.down("sm")]: {
				flexDirection: "column"
			}
		},
		bottom: {
			alignItems: "center"
		}
	};
});

export default Footer;

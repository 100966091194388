import React from "react";
import { Helmet } from "react-helmet";
import { navigate } from "@reach/router";

import { AuthProvider, RunlyProvider } from "@runly/ui";

const Wrapper = ({ element }) => (
	<AuthProvider
		apiUrl={process.env.API_URL}
		clientId={process.env.AUTH0_CLIENT_ID}
		navigate={navigate}
	>
		{accessToken => (
			<RunlyProvider accessToken={accessToken} url={process.env.API_URL}>
				<Helmet>
					<link rel="stylesheet" href="https://use.typekit.net/gvc2afg.css" />
					<link
						rel="stylesheet"
						href="https://cdn.jsdelivr.net/gh/tonsky/FiraCode@1.207/distr/fira_code.css"
					/>
				</Helmet>
				{element}
			</RunlyProvider>
		)}
	</AuthProvider>
);

export default Wrapper;

module.exports = [{
      plugin: require('../../node_modules/gatsby-theme-material-ui-top-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"stylesProvider":{"injectFirst":true}},
    },{
      plugin: require('../../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"gatsbyRemarkPlugins":["gatsby-remark-copy-linked-files","gatsby-remark-autolink-headers",{"resolve":"gatsby-remark-vscode","options":{"theme":{"default":"Dark+ (default dark)","injectStyles":false}}},"gatsby-remark-smartypants",{"resolve":"gatsby-remark-images","options":{"maxWidth":833,"linkImagesToOriginal":false}}]},
    },{
      plugin: require('../../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":833,"linkImagesToOriginal":false},
    },{
      plugin: require('../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Runly","short_name":"Runly","icon":"/opt/build/repo/theme/src/favicon.svg","start_url":"/dashboard/","scope":"/","background_color":"#dee6ec","theme_color":"#27579c","display":"standalone"},
    },{
      plugin: require('../../node_modules/gatsby-plugin-rollbar/gatsby-browser.js'),
      options: {"plugins":[],"enabled":true,"accessToken":"8429f8c575844f458d6cd953b5df91c8","captureUncaught":true,"captureUnhandledRejections":true,"payload":{"environment":"production","client":{"javascript":{"source_map_enabled":true,"guess_uncaught_frames":true,"code_version":"51bf881b7f5eb53eec223201ca98528721be97ba"}}}},
    },{
      plugin: require('../../theme/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["UA-145795781-1","AW-654400609"],"exclude":["/dashboard/runly/**","/auth"]},
    }]

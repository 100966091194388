import React from "react";
import { Box, Typography, CircularProgress } from "@material-ui/core";

const LoadingIndicator = ({
	className = "",
	children,
	size,
	...typographyProps
}) => (
	<Box
		my={2}
		mx="auto"
		display="flex"
		alignItems="center"
		justifyContent="center"
		className={className}
	>
		<Box>
			<Box mb={children ? 2 : 0} textAlign="center">
				<CircularProgress data-testid="loading-indicator" size={size} />
			</Box>
			{children ? (
				<Typography {...typographyProps}>{children}</Typography>
			) : null}
		</Box>
	</Box>
);

export default LoadingIndicator;

import React from "react";

import MDXRenderer from "gatsby-plugin-mdx/mdx-renderer";
import MdxLayout from "./layout";

const Mdx = ({ components, ...props }) => {
	return (
		<MdxLayout components={components}>
			<MDXRenderer {...props} />
		</MdxLayout>
	);
};

export default Mdx;

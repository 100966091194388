import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import { SvgIcon } from "@material-ui/core";

export const Icon = props => (
	<SvgIcon {...props} viewBox="0 0 1111.42 1768.73">
		<g>
			<polygon points="1.02,1768.73 447.96,1768.73 1111.42,885.04 446.94,0 0,0 666.15,885.04" />
		</g>
	</SvgIcon>
);

export const Full = props => {
	const classes = useStyles(props);

	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			viewBox="0 0 1000.98 327.65"
			{...props}
		>
			<g>
				<path
					className={classes.words}
					d="M269,97c0,0,15.77-20,61-20l-0.11,46.79c-29.93,0-50.55,7.98-59.86,28.27v111.41h-54.54V80.55h43.23L269,97z"
				/>
				<path
					className={classes.words}
					d="M472.56,263.46L465,247c0,0-13.72,20.79-47.97,20.79c-39.58,0-60.53-22.28-60.53-62.85V80.55h54.54v111.41
		c0,20.62,7.32,30.6,23.28,30.6c20.95,0,28.93-15.96,28.93-15.96V80.55h54.54v182.91H472.56z"
				/>
				<path
					className={classes.words}
					d="M716.32,141.75v121.72h-54.87V144.74c0-14.97-6.65-23.61-19.95-23.61c-20.29,0-32.92,15.63-32.92,15.63v126.71
		H553.7V80.55h44.23L606,97c2.66-2.99,21.53-20.77,53.79-20.77C705.35,76.23,716.32,99.84,716.32,141.75z"
				/>
				<path
					className={classes.words}
					d="M751.57,263.46l3-242.77h54.54l-3,242.77H751.57z"
				/>
				<path
					className={classes.words}
					d="M1000.98,234.2c0,62.85-30.93,93.45-90.46,93.45c-26.94,0-42.21-5.32-47.53-7.65l-14-48
		c5.32,2.99,32.93,14.74,60.2,14.74c23.61,0,36.8-13.74,38.8-33.74l1.44-16.81c0,0-18.62,23.61-49.55,23.61
		c-42.24,0-58.2-25.61-58.2-65.85V80.55h54.54v111.74c0,15.3,7.32,24.61,20.95,24.61c18.29,0,29.27-16.29,29.27-16.29V80.55h54.54
		V234.2z"
				/>
				<polygon
					className={classes.icon}
					points="0.16,285 72.18,285 179.09,142.61 72.02,0 0,0 107.34,142.61 	"
				/>
			</g>
		</svg>
	);
};

const useStyles = makeStyles({
	words: {
		fill: "#ffffff"
	},
	icon: {
		fill: ({ isOnHero, isLandingPage }) =>
			isLandingPage || !isOnHero ? "#3fa7f5" : "#fff"
	}
});

import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`The `}<inlineCode parentName="p">{`RunProgress`}</inlineCode>{` component allows you to show a progress bar indicating a run’s status for a particular run. Once a run has completed, the component will optionally render a `}<em parentName="p">{`Retry`}</em>{` button to rerun a job that has failed (either partially or fully). See the retry mechanism in action in our `}<a parentName="p" {...{
        "href": "/docs/examples/web/#retrying-job-runs"
      }}>{`example web application walkthrough`}</a>{`.`}</p>

    <style {...{
      "className": "grvsc-styles"
    }}>{`
  .grvsc-container {
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    padding-top: 1rem;
    padding-top: var(--grvsc-padding-top, var(--grvsc-padding-v, 1rem));
    padding-bottom: 1rem;
    padding-bottom: var(--grvsc-padding-bottom, var(--grvsc-padding-v, 1rem));
    border-radius: 8px;
    border-radius: var(--grvsc-border-radius, 8px);
    font-feature-settings: normal;
  }
  
  .grvsc-code {
    display: inline-block;
    min-width: 100%;
  }
  
  .grvsc-line {
    display: inline-block;
    box-sizing: border-box;
    width: 100%;
    padding-left: 1.5rem;
    padding-left: var(--grvsc-padding-left, var(--grvsc-padding-h, 1.5rem));
    padding-right: 1.5rem;
    padding-right: var(--grvsc-padding-right, var(--grvsc-padding-h, 1.5rem));
  }
  
  .grvsc-line-highlighted {
    background-color: var(--grvsc-line-highlighted-background-color, transparent);
    box-shadow: inset var(--grvsc-line-highlighted-border-width, 4px) 0 0 0 var(--grvsc-line-highlighted-border-color, transparent);
  }
  
`}</style>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      
/* eslint-disable react/display-name */

import React from "react";
import { MDXProvider } from "@mdx-js/react";
import {
	Typography,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import Note from "../note";
import CodeBlock from "../code-block";

import { Link, Button } from "gatsby-theme-material-ui";

const DocsH = ({ variant, component, ...props }) => {
	const classes = useStyles();

	return (
		<Typography
			gutterBottom
			className={classes.heading}
			{...{ variant, component }}
			{...props}
		/>
	);
};

const DocsH1 = props => <DocsH variant="h3" component="h1" {...props} />;
const DocsH2 = props => <DocsH variant="h4" component="h2" {...props} />;
const DocsH3 = props => {
	const classes = useStyles();

	return (
		<Typography
			variant="h5"
			component="h3"
			gutterBottom
			className={classes.heading}
			{...props}
		/>
	);
};

const baseComponents = {
	Note,
	p: props => <Typography variant="body1" paragraph {...props} />,
	h1: DocsH1,
	h2: DocsH2,
	h3: DocsH3,
	h4: props => (
		<Typography variant="h6" component="h4" gutterBottom {...props} />
	),
	h5: props => (
		<Typography variant="h6" component="h5" gutterBottom {...props} />
	),
	h6: props => <Typography variant="h6" gutterBottom {...props} />,
	ul: props => (
		<Typography variant="body1" component="ul" paragraph {...props} />
	),
	ol: props => (
		<Typography variant="body1" component="ol" paragraph {...props} />
	),
	pre: CodeBlock,
	a: ({ href, ...props }) => <Link to={href} {...props} />,
	table: props => (
		<TableContainer component={Paper}>
			<Table {...props} />
		</TableContainer>
	),
	thead: props => <TableHead {...props} />,
	tbody: props => <TableBody {...props} />,
	tr: props => <TableRow {...props} />,
	th: ({ align, ...props }) => (
		<TableCell component="th" {...{ ...props, align: align || "" }} />
	),
	td: ({ align, ...props }) => (
		<TableCell {...{ ...props, align: align || "" }} />
	),
	button: props => <Button {...props} />
};

const Layout = ({ components, children }) => {
	components = { ...baseComponents, ...components };

	return <MDXProvider components={components}>{children}</MDXProvider>;
};

const useStyles = makeStyles(theme => ({
	heading: {
		marginTop: theme.spacing(3)
	}
}));

export default Layout;

import React, { useState } from "react";
import { Box } from "@material-ui/core";
import { LanguageTabs } from "./language-tabs";

const CodeSample = ({ langs, PickerComponent = LanguageTabs, ...props }) => {
	const [language, setLanguage] = useState("csharp");

	return (
		<Box {...props}>
			<PickerComponent {...{ language, setLanguage }} langs={langs} />
			{langs[language]}
		</Box>
	);
};

export default CodeSample;

// UI theme colors for material
export const RUNLY_TEXT = "#29333D";
export const RUNLY_PRIMARY = "#3685D9";
export const RUNLY_SECONDARY = "#5190E8";

// Additional branding colors
export const RUNLY_BLUE = "#3fA7f5";
export const RUNLY_DARK_BLUE = "#27579c";
export const RUNLY_DARKER_BLUE = "#07003f";
export const RUNLY_APPBAR_GRADIENT_L = RUNLY_DARK_BLUE;
export const RUNLY_APPBAR_GRADIENT_R = RUNLY_BLUE;

export const RUNLY_APPBAR_GRADIENT = `linear-gradient(to right, ${RUNLY_APPBAR_GRADIENT_L}, 72%, ${RUNLY_APPBAR_GRADIENT_R})`;
export const RUNLY_MINT = "#00e3a9";
export const RUNLY_DARK_MINT = "#00c490";
export const RUNLY_MID_GRAY = "#76808A";
export const RUNLY_GRAY = "#5c6670";
export const RUNLY_DARK_GRAY = "#484d52";
export const RUNLY_LIGHT_GRAY = "#DEE6EC";
export const RUNLY_LIGHTER_GRAY = "#fafefc";
export const solarized = {
	base03: "#002b36",
	base02: "#073642",
	base01: "#586e75",
	base00: "#657b83",
	base0: "#839496",
	base1: "#93a1a1",
	base2: "#eee8d5",
	base3: "#fdf6e3",
	yellow: "#b58900",
	orange: "#cb4b16",
	red: "#dc322f",
	magenta: "#d33682",
	violet: "#6c71c4",
	blue: "#268bd2",
	cyan: "#2aa198",
	green: "#859900"
};

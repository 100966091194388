import React from "react";
import { ListItemText } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const FeaturedListItemText = ({ primaryTypographyProps, ...props }) => {
	const classes = useStyles();

	const typography = {
		...primaryTypographyProps,
		...{ className: classes.featuredText }
	};

	return <ListItemText {...props} primaryTypographyProps={typography} />;
};

const useStyles = makeStyles(theme => ({
	featuredText: {
		fontFamily: theme.typography.h1.fontFamily,
		fontWeight: theme.typography.h1.fontWeight,
		fontSize: theme.typography.h4.fontSize
	}
}));

export default FeaturedListItemText;

import { useState, useCallback } from "react";

const useMenu = () => {
	const [anchorEl, setAnchorEl] = useState();
	const onClick = useCallback(ev => setAnchorEl(ev.currentTarget), []);
	const onClose = useCallback(() => setAnchorEl(null), []);
	const buttonProps = { onClick };
	const menuProps = { anchorEl, onClose, open: !!anchorEl };
	return { anchorEl, onClick, onClose, buttonProps, menuProps };
};

export const belowPopProps = {
	getContentAnchorEl: null,
	anchorOrigin: {
		vertical: "bottom",
		horizontal: "left"
	},
	transformOrigin: {
		vertical: "top",
		horizontal: "left"
	}
};

export default useMenu;

import React, { createContext } from "react";
import useDarkMode from "./hook";

const DarkModeContext = createContext();

const DarkModeProvider = props => {
	const { darkMode, setDarkMode } = useDarkMode();
	return (
		<DarkModeContext.Provider value={{ darkMode, setDarkMode }}>
			{props.children}
		</DarkModeContext.Provider>
	);
};
export { DarkModeContext, DarkModeProvider };

import React, { useMemo, useContext } from "react";

import _CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/styles";

import Viewport from "./viewport";
import makeTheme from "../theme";
import { DarkModeProvider, DarkModeContext } from "../../dark-mode/context";

const CssBaseline = React.memo(
	_CssBaseline,
	(prevProps, nextProps) => prevProps.theme === nextProps.theme
);

const TopLayoutInner = ({ children }) => {
	const { darkMode } = useContext(DarkModeContext);
	const theme = useMemo(() => makeTheme({ darkMode }), [darkMode]);
	return (
		<>
			<Viewport />

			<ThemeProvider theme={theme}>
				{/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
				<CssBaseline />

				{children}
			</ThemeProvider>
		</>
	);
};

const TopLayout = ({ children }) => {
	return (
		<DarkModeProvider>
			<TopLayoutInner>{children}</TopLayoutInner>
		</DarkModeProvider>
	);
};

export default TopLayout;

import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import { yellow } from "@material-ui/core/colors";

const CodeBlock = props => {
	const classes = useStyles();
	const mainChild = React.Children.only(props.children);
	const hasHighlights = React.Children.toArray(
		mainChild?.props?.children
	).some(c => c?.props?.className.includes("grvsc-line-highlighted"));

	return (
		<pre
			{...props}
			className={`${classes.codeBlock} ${props.className} ${
				hasHighlights ? classes.hasHighlights : null
			}`}
		/>
	);
};

const useStyles = makeStyles(theme => {
	return {
		codeBlock: {
			tabSize: 4
		},
		"@global": {
			".grvsc-container": {
				counterReset: "line",
				padding: [theme.spacing(3, 1), "!important"],
				"--grvsc-border-radius": `${theme.spacing(2)}px`,
				"--grvsc-padding-left": `${theme.spacing(5)}px`,
				fontSize: theme.typography.fontSize
			},
			".grvsc-line::before": {
				counterIncrement: "line",
				content: "counter(line)",
				opacity: 0.5,
				margin: theme.spacing(0, 4, 0, -4),
				userSelect: "none",
				display: "inline-block",
				width: 0
			},
			'[data-language="sh"] .grvsc-line::before, [data-language="ps"] .grvsc-line::before': {
				content: "none"
			},
			".grvsc-line:hover::before": {
				opacity: 1
			},
			".grvsc-container .grvsc-line-highlighted": {
				backgroundColor: "rgba(0, 0, 0, 0.2)",
				boxShadow: "none",
				borderLeft: `${theme.spacing(0.5)}px solid ${yellow["700"]}`,
				marginLeft: -theme.spacing(0.5)
			}
		},
		hasHighlights: {
			"& .grvsc-line": { opacity: 0.65 },
			"& .grvsc-line-highlighted": { opacity: 1 }
		}
	};
});

export default CodeBlock;

import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Boolean flag that controls whether or not the component will show a `}<em parentName="p">{`Retry`}</em>{` button if the run partially or fully fails. This defaults to `}<inlineCode parentName="p">{`true`}</inlineCode>{`.`}</p>
    <p>{`A run is considered failed if it completes with any status other than `}<inlineCode parentName="p">{`Successful`}</inlineCode>{`. If the run does complete with a status of `}<inlineCode parentName="p">{`Successful`}</inlineCode>{` but there are some failed items, this is considered a partial failure and the `}<em parentName="p">{`Retry`}</em>{` button will render. Learn more about how `}<a parentName="p" {...{
        "href": "/docs/fault-tolerance/"
      }}>{`Runly jobs can finish successfully while tolerating failures`}</a>{`.`}</p>

    <style {...{
      "className": "grvsc-styles"
    }}>{`
  .grvsc-container {
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    padding-top: 1rem;
    padding-top: var(--grvsc-padding-top, var(--grvsc-padding-v, 1rem));
    padding-bottom: 1rem;
    padding-bottom: var(--grvsc-padding-bottom, var(--grvsc-padding-v, 1rem));
    border-radius: 8px;
    border-radius: var(--grvsc-border-radius, 8px);
    font-feature-settings: normal;
  }
  
  .grvsc-code {
    display: inline-block;
    min-width: 100%;
  }
  
  .grvsc-line {
    display: inline-block;
    box-sizing: border-box;
    width: 100%;
    padding-left: 1.5rem;
    padding-left: var(--grvsc-padding-left, var(--grvsc-padding-h, 1.5rem));
    padding-right: 1.5rem;
    padding-right: var(--grvsc-padding-right, var(--grvsc-padding-h, 1.5rem));
  }
  
  .grvsc-line-highlighted {
    background-color: var(--grvsc-line-highlighted-background-color, transparent);
    box-shadow: inset var(--grvsc-line-highlighted-border-width, 4px) 0 0 0 var(--grvsc-line-highlighted-border-color, transparent);
  }
  
`}</style>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      
import React from "react";
import { Tabs, Tab } from "@material-ui/core";
import { LanguageChip } from "./language-chip";
import { makeStyles } from "@material-ui/core/styles";

export const LanguageTabs = ({ language, setLanguage, langs }) => {
	const classes = useStyles();
	return (
		<>
			<Tabs value={Object.keys(langs).indexOf(language)}>
				{Object.keys(langs).map(l => (
					<Tab
						key={l}
						selected={l === language}
						onClick={() => {
							setLanguage(l);
						}}
						label={<LanguageChip language={l} />}
						classes={classes}
					/>
				))}
			</Tabs>
		</>
	);
};

const useStyles = makeStyles(theme => {
	return { root: { ...theme.typography.body1, textTransform: "none" } };
});

import React from "react";
import { SvgIcon, Box, Typography } from "@material-ui/core";
import { mdiNodejs, mdiLanguageCsharp } from "@mdi/js";

export const LANGUAGES = {
	javascript: {
		label: "Node.js",
		icon: mdiNodejs,
		iconColor: "#2f7c47",
		bgColor: "#000"
	},
	csharp: {
		label: ".NET",
		icon: mdiLanguageCsharp,
		iconColor: "#fff",
		bgColor: "#390086"
	}
};
export const LanguageChip = ({ language }) => {
	const { icon, label, iconColor, bgColor } = LANGUAGES[language];
	return (
		<Box display="flex">
			<Box bgcolor={bgColor} width="1.75em" height="1.75em" mr={1}>
				<SvgIcon>
					<path fill={iconColor} d={icon} />
				</SvgIcon>
			</Box>
			<Typography variant="body1">{label}</Typography>
		</Box>
	);
};

import { responsiveFontSizes, createMuiTheme } from "@material-ui/core/styles";
import tc from "tinycolor2";
import {
	solarized,
	RUNLY_TEXT,
	RUNLY_BLUE,
	RUNLY_LIGHT_GRAY,
	RUNLY_LIGHTER_GRAY,
	RUNLY_PRIMARY,
	RUNLY_SECONDARY
} from "../colors";

const createTheme = ({
	darkMode: _darkMode,
	fontSize = 13,
	spacing = 8,
	...outerTheme
}) => {
	const darkMode = outerTheme?.palette?.type === "dark" || _darkMode;
	const type = outerTheme?.palette?.type || darkMode ? "dark" : "light";
	const primaryMain = darkMode
		? tc(RUNLY_BLUE).lighten().desaturate().toString()
		: RUNLY_PRIMARY;
	const secondaryLight = darkMode
		? tc(RUNLY_SECONDARY).lighten(13).desaturate().toString()
		: tc(RUNLY_SECONDARY).lighten().toString();
	const secondaryMain = darkMode
		? tc(RUNLY_SECONDARY).lighten().desaturate().toString()
		: RUNLY_SECONDARY;
	const secondaryDark = darkMode
		? tc(RUNLY_SECONDARY).desaturate().toString()
		: tc(RUNLY_SECONDARY).darken().toString();
	const background = {
		default: darkMode
			? tc(solarized.base03).desaturate(50).toString()
			: RUNLY_LIGHT_GRAY,
		paper: darkMode
			? tc(solarized.base02).desaturate(50).toString()
			: RUNLY_LIGHTER_GRAY
	};

	const textBase = darkMode ? RUNLY_LIGHTER_GRAY : RUNLY_TEXT;
	const codeTextBase = RUNLY_LIGHTER_GRAY;

	const divider = tc(textBase).setAlpha(0.12).toString();
	const codeDivider = tc(codeTextBase).setAlpha(0.12).toString();
	const tableRuleColor = tc(divider).setAlpha(0.08).toString();

	const theme = responsiveFontSizes(
		createMuiTheme({
			spacing,
			typography: {
				fontSize,
				fontFamily: FONT_STACK.join(","),
				h1: { fontFamily: HEADER_FONT_STACK, fontWeight: 400 },
				h2: { fontFamily: HEADER_FONT_STACK, fontWeight: 400 },
				h3: { fontFamily: HEADER_FONT_STACK, fontWeight: 700 },
				h4: { fontFamily: HEADER_FONT_STACK, fontWeight: 700 },
				h5: {
					fontFamily: HEADER_FONT_STACK,
					fontWeight: 700,
					textTransform: "uppercase"
				},
				h6: { fontFamily: HEADER_FONT_STACK, fontWeight: 600 }
			},
			palette: {
				type,
				primary: {
					main: primaryMain,
					contrastText: tc(primaryMain).isDark() ? "#fff" : "#000"
				},
				secondary: {
					light: secondaryLight,
					main: secondaryMain,
					dark: secondaryDark,
					contrastText: tc(secondaryMain).isDark() ? "#fff" : "#000"
				},
				background,
				text: {
					primary: tc(textBase).setAlpha(0.87).toString(),
					secondary: tc(textBase).setAlpha(0.54).toString(),
					disabled: tc(textBase).setAlpha(0.38).toString(),
					hint: tc(textBase).setAlpha(0.38).toString()
				},
				divider
			},
			overrides: {
				MuiListSubheader: {
					colorInherit: {
						opacity: 0.87
					}
				},
				MuiButton: {
					root: {
						borderRadius: 0
					}
				},
				MuiTableCell: {
					root: {
						borderBottom: `1px solid ${tableRuleColor}`
					}
				},
				MuiCssBaseline: {
					"@global": {
						"code, h1 code, h3 code": {
							fontFamily: ["Fira Code, monospace", "!important"],
							fontWeight: ["normal", "!important"],
							textTransform: ["none", "!important"]
						},
						"*::-webkit-scrollbar": {
							width: "0.5em",
							height: "0.5em"
						},
						"*::-webkit-scrollbar-track": {
							"-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)"
						},
						"*::-webkit-scrollbar-thumb": {
							backgroundColor: divider,
							outline: `1px solid ${tc(divider).darken().toString()}`,
							"*:hover > &, *:focus > &": {
								backgroundColor: tc(divider)
									.setAlpha(tc(divider).getAlpha() + 0.1)
									.toString()
							}
						},
						"pre::-webkit-scrollbar-thumb": {
							backgroundColor: codeDivider,
							outline: `1px solid ${tc(codeDivider).darken().toString()}`,
							"div:hover &, div:focus > &": {
								backgroundColor: tc(codeDivider)
									.setAlpha(tc(codeDivider).getAlpha() + 0.1)
									.toString()
							}
						}
					}
				},
				MuiTypography: {
					paragraph: {
						marginBottom: () => theme.spacing(2)
					}
				}
			},
			props: {
				...denseProps,
				MuiButton: { color: "inherit", ...denseProps.MuiButton },
				MuiPaper: { square: true },
				MuiListSubheader: {
					color: "inherit"
				},
				MuiListItemText: {
					primaryTypographyProps: { component: "div" },
					secondaryTypographyProps: { component: "div" }
				},
				MuiTextField: {
					...denseProps.MuiTextField,
					variant: "outlined"
				},
				MuiCollapse: {
					mountOnEnter: true,
					unmountOnExit: true
				}
			}
		})
	);

	return theme;
};

const HEADER_FONT_STACK = ["Interstate", "sans-serif"].join(", ");

const FONT_STACK = [
	"Open Sans",
	'"Helvetica Neue"',
	"Arial",
	"sans-serif",
	'"Apple Color Emoji"',
	'"Segoe UI Emoji"',
	'"Segoe UI Symbol"'
];

const denseProps = {
	MuiButton: {
		size: "small"
	},
	MuiFilledInput: {
		margin: "dense"
	},
	MuiFormControl: {
		margin: "dense"
	},
	MuiFormHelperText: {
		margin: "dense"
	},
	MuiIconButton: {
		size: "small"
	},
	MuiInputBase: {
		margin: "dense"
	},
	MuiInputLabel: {
		margin: "dense"
	},
	MuiListItem: {
		dense: true
	},
	MuiOutlinedInput: {
		margin: "dense"
	},
	MuiFab: {
		size: "small"
	},
	MuiTable: {
		size: "small"
	},
	MuiTextField: {
		margin: "dense"
	},
	MuiToolbar: {
		variant: "dense"
	}
};

export default createTheme;

// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---app-src-auth-js": () => import("./../../app/src/auth.js" /* webpackChunkName: "component---app-src-auth-js" */),
  "component---app-src-index-js": () => import("./../../app/src/index.js" /* webpackChunkName: "component---app-src-index-js" */),
  "component---src-doc-index-js": () => import("./../src/doc/index.js" /* webpackChunkName: "component---src-doc-index-js" */),
  "component---src-landing-ad-js": () => import("./../src/landing/ad.js" /* webpackChunkName: "component---src-landing-ad-js" */),
  "component---src-legal-js": () => import("./../src/legal.js" /* webpackChunkName: "component---src-legal-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-downloads-js": () => import("./../src/pages/downloads.js" /* webpackChunkName: "component---src-pages-downloads-js" */),
  "component---src-pages-help-js": () => import("./../src/pages/help.js" /* webpackChunkName: "component---src-pages-help-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-platform-js": () => import("./../src/pages/platform.js" /* webpackChunkName: "component---src-pages-platform-js" */),
  "component---src-pages-pricing-js": () => import("./../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-ui-js": () => import("./../src/pages/ui.js" /* webpackChunkName: "component---src-pages-ui-js" */),
  "component---src-pages-videos-js": () => import("./../src/pages/videos.js" /* webpackChunkName: "component---src-pages-videos-js" */),
  "component---src-runly-gatsby-theme-blog-home-js": () => import("./../src/@runly/gatsby-theme-blog/home.js" /* webpackChunkName: "component---src-runly-gatsby-theme-blog-home-js" */),
  "component---src-runly-gatsby-theme-blog-post-js": () => import("./../src/@runly/gatsby-theme-blog/post.js" /* webpackChunkName: "component---src-runly-gatsby-theme-blog-post-js" */),
  "component---src-runly-gatsby-theme-docfx-template-js": () => import("./../src/@runly/gatsby-theme-docfx/template.js" /* webpackChunkName: "component---src-runly-gatsby-theme-docfx-template-js" */)
}


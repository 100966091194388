import { useCallback } from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import createPersistedState from "use-persisted-state";

const useDarkModeState = createPersistedState("darkMode");

const useDarkMode = () => {
	const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
	const [darkMode, _setDarkMode] = useDarkModeState(prefersDarkMode);

	const setDarkMode = useCallback(
		value => {
			_setDarkMode(value);
		},
		[_setDarkMode]
	);
	return { darkMode, setDarkMode };
};

export default useDarkMode;

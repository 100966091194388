// HACK: this file has to be in the root (not in src)
// https://github.com/gatsbyjs/gatsby/issues/14068

import { Icon as LogoIcon, Full as Logo } from "./src/logo";

export * from "gatsby-theme-material-ui"; // eslint-disable-line import/export
export * from "./src/dark-mode/context";
export { default as useDarkMode } from "./src/dark-mode/hook";
export * from "./src/colors";
export { default as FeaturedListItemText } from "./src/featured-list-item-text";

export { default as Footer } from "./src/footer";

export { LogoIcon, Logo };

export { default as Mdx } from "./src/mdx";
export { default as MdxLayout } from "./src/mdx/layout";

export { default as Note } from "./src/note";

export { default as CodeBlock } from "./src/code-block";
export { default as CodeSample } from "./src/code-sample";

export { default as LoadingIndicator } from "./src/loading-indicator";

export { default as createTheme } from "./src/gatsby-theme-material-ui-top-layout/theme";

export { default as analytics } from "./src/analytics";

export { default as useMenu, belowPopProps } from "./src/use-menu";

import React, { Children, isValidElement } from "react";

import { Box } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";

const Note = ({ title, children, severity = "info", ...props }) => (
	<Box clone my={3}>
		<Alert severity={severity} {...props}>
			{title ? <AlertTitle>{title}</AlertTitle> : null}
			<div>
				{Children.map(children, item => {
					if (!isValidElement(item)) {
						return item;
					}

					const pChildren = React.Children.only(item).props.children;
					return pChildren;
				})}
			</div>
		</Alert>
	</Box>
);

export default Note;

import React from "react";

import { Link as ExternalLink } from "@material-ui/core";
import { Link as LocalLink } from "gatsby-theme-material-ui";

const Link = ({ href, ...props }) => {
	if (href.startsWith("http")) {
		return <ExternalLink href={href} target="_blank" {...props} />;
	}

	return <LocalLink to={href} {...props} />;
};

export default Link;
